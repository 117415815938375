import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCookieBite } from '@fortawesome/free-solid-svg-icons';
import './CookieBanner.css';

const CookieBanner = () => {
    const [isBannerVisible, setBannerVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    // show/hide banner based on previous consent flag from local storage
    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setBannerVisible(true);
        } else {
            setCookiesAccepted(true);
            enableGA4Tracking();
        }
    }, []);

    // handle consent accept and load GA4 tracking
    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setCookiesAccepted(true);
        setBannerVisible(false);
        enableGA4Tracking();
    };

    // GA4 initialization based on consent
    const enableGA4Tracking = () => {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.dataLayer.push({ event: 'consent_given' });

        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7B3XHVPKXH';
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.gtag = function () {
            window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', 'G-7B3XHVPKXH');
        };
    };

    // close cookie banner
    const handleClose = () => {
        setBannerVisible(false);
    };

    // show learn more modal
    const handleLearnMore = () => {
        setModalVisible(true);
    };

    // hide learn more modal
    const closeModal = () => {
        setModalVisible(false);
    };

    // if banner not visible or cookies already accepted, then don't return anything
    if (!isBannerVisible || cookiesAccepted) return null;

    // return cookie banner (+ learn more modal)
    return (
        <>
        <div className="cookie-banner">
            <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={handleClose} title="Close" />
            <p>
                This website uses cookies to enhance your experience and collect analytics data (GA4). By clicking "Accept", you agree to our use of cookies.&nbsp;
                <span className="learn-more" onClick={handleLearnMore}>Learn more</span>.
            </p>
            <button onClick={handleAccept} className="btn btn-primary btn-sm">Accept</button>
            <button onClick={handleClose} className="btn btn-primary btn-sm close-button">Close</button>
        </div>

        {isModalVisible && (
            <div className="cookie-modal">
                <div className="cookie-modal-content">
                    <button className="close-modal" onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={handleClose} title="Close" />
                    </button>
                    <h4><FontAwesomeIcon icon={faCookieBite} /> Cookie Policy</h4>
                    <p>This website uses cookies to enhance your experience and collect analytics data using Google Analytics 4 (GA4).</p>
                    <h4>What are cookies?</h4>
                    <p>Cookies are small data files that are stored on your device to improve website performance and functionality.</p>
                    <h4>How do we use cookies?</h4>
                    <ul>
                        <li>Tracking website performance and usage (via GA4).</li>
                        <li>Understanding user behavior to improve user experience.</li>
                    </ul>
                    <h4>How to manage or delete cookies?</h4>
                    <p>You can manage or delete cookies through your browser settings. For more details, please refer to your browser's help section.</p>
                </div>
            </div>
        )}
        </>
    );
};

export default CookieBanner;