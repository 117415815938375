// about cards
export const about = [
    {
      imgUrl: '/images/individualized-tutoring.webp',
      title: 'Individualized Tutoring',
      description: "Your child's learning style is unique - we specialize in providing what traditional group learning formats cannot. Our tailored lessons are designed to meet your child's individual needs."
    },
    {
      imgUrl: '/images/diagnostic-testing.webp',
      title: 'Diagnostic Testing',
      description: 'Building on what the school system offers, we use comprehensive, research-backed diagnostic testing to identify the most effective educational approach for your child.'
    },
    {
      imgUrl: '/images/certified-educators.webp',
      title: 'Certified Educators',
      description: 'Our staff consists entirely of certified Texas public school educators, ensuring your child receives the highest quality education beyond the traditional school system.'
    },
];

// services cards
export const services = [
    {
        title: 'Private Tutoring',
        price: "From $85/hour",
        description: 'Private 1 hour in-person tutoring sessions for any subject'
    },
    {
        title: 'Test Preparation',
        price: "$100/hour",
        description: 'Private 1 hour in-person test preparation sessions'
    },
    {
        title: 'Homeschool Support',
        price: "Contact us for pricing",
        description: 'Private and flexible in-person homeschool support'
    },
];