import React, { useState } from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faUsers, faList, faEnvelope, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <div className="navbar-logo-icon">
          <FontAwesomeIcon icon={faAppleAlt} size="xl"/>
        </div>
        <div className="navbar-logo-text">
          <h2>Whaley</h2>
          <p>Educational Services, LLC</p>
        </div>
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <a href="#about"><FontAwesomeIcon className="navbar-link-icon" icon={faUsers} />About Us</a>
        <a href="#services"><FontAwesomeIcon className="navbar-link-icon navbar-services-icon" icon={faList} />Services</a>
        <a href="#contact"><FontAwesomeIcon className="navbar-link-icon" icon={faEnvelope} />Contact Us</a>
      </div>
      <div className="navbar-toggle" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="xs" />
      </div>
    </nav>
  );
};

export default Navbar;