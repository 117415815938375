import React from 'react';
import './ServicesCards.css';

const ServicesCards = ({data}) => {
  return (
    <div className="card-container">
      <div className="row">
        {data.map((card, index) => (
          <div className="col-md-4" key={index}>
            <div className="card border rounded">
              <div className="card-body m-1">
                <h4 className="mb-3">{card.title}</h4>
                <p className="card-price">{card.price}</p>
                <p className="card-text">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesCards;