import React from 'react';
import './Contact.css';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div className="contact-container">
        <div className="contact-form-container">
            <ContactForm />
        </div>
        <div className="contact-testimonial">
            <img src="/images/anne-whaley.png" alt="Anne Whaley"></img>
            <h4>"Students need one-on-one help with their academic needs. In the realm of learning, one size does not fit all. I'm looking forward to working with you!"</h4>
            <h4>Anne Whaley M.Ed., Owner & Operator</h4>
      </div>
    </div>
  );
};

export default Contact;