import React from 'react';
import './Services.css';
import ServicesCards from './ServicesCards';
import { services } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-header">
        <h2><FontAwesomeIcon className="services-icon" icon={faList} size="xs"/>Services</h2>
        <p>We offer the following services. Please <a href="#contact">contact us</a> if you have any questions or want more information.</p>
      </div>
      <ServicesCards data={services}/>
    </div>
  );
};

export default Services;