import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faMapPin, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import PrivacyPolicy from './PrivacyPolicy';
import './Footer.css';

const Footer = () => {
    const [isPrivacyModalVisible, setPrivacyModalVisible] = useState(false);

  return (
    <footer className="text-center text-lg-start">
        <div className="footer-main">
            <div className="row">
                <div className="col-lg-6 col-md-6 mb-4 mb-md-0 footer-section">
                    <div className="navbar-logo">
                        <div className="navbar-logo-icon">
                            <FontAwesomeIcon icon={faAppleAlt} size="xl"/>
                        </div>
                        <div className="navbar-logo-text">
                            <h2>Whaley</h2>
                            <p>Educational Services, LLC</p>
                        </div>
                    </div>
                    <div className="footer-address">
                        <a href="https://maps.app.goo.gl/8vVe549hY5wqp3Yq9" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faMapPin} size="sm"/>&ensp;600 S. Bell Blvd Suite 16, Cedar Park, TX 78613
                        </a>
                        <br />
                        <a href="tel:+15123945068">
                        <FontAwesomeIcon icon={faPhone} size="sm"/>&ensp;(512) 394-5068
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 mb-md-0 footer-section">
                    <h5 className="text-uppercase">Quick Links</h5>
                    <ul className="list-unstyled">
                        <li>
                            <a href="#home">Home</a>
                        </li>
                        <li>
                            <a href="#about">About Us</a>
                        </li>
                        <li>
                            <a href="#services">Services</a>
                        </li>
                        <li>
                            <a href="#contact">Contact Us</a>
                        </li>
                    </ul>
                    <div className="privacy-policy">
                        <button onClick={() => setPrivacyModalVisible(true)}>Privacy Policy</button>
                        <PrivacyPolicy
                            visible={isPrivacyModalVisible}
                            onClose={() => setPrivacyModalVisible(false)}
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 mb-md-0 footer-section">
                    <h5 className="text-uppercase">Follow Us</h5>
                    <div>
                        <a href="https://m.facebook.com/profile.php?id=100087958698546" target="_blank" rel="noreferrer" className="me-4">
                            <FontAwesomeIcon icon={faFacebook} size="lg" />
                        </a>
                        <a href="https://twitter.com/McWhaleyEd" target="_blank" rel="noreferrer" className="me-4">
                            <FontAwesomeIcon icon={faXTwitter} size="lg" />
                        </a>
                        <a href="https://www.instagram.com/tutor4youcp" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="lg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-center footer-copyright">
        © 2024 Whaley Educational Services, LLC. All rights reserved.
        Website by <a href="https://lukewhaley.io" target="_blank" rel="noreferrer">Luke Whaley</a>.
        </div>
    </footer>
  );
};

export default Footer;