import React from 'react';
import './About.css';
import AboutCards from './AboutCards';
import { about } from '../data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  return (
    <div className="about-container">
        <div className="about-header">
            <h2><FontAwesomeIcon className="about-icon" icon={faUsers} size="xs"/>About Us</h2>
            <p>Whaley Educational Services, LLC is a locally owned and operated education and tutoring center in Cedar Park, TX. We were founded by certified Texas educators who believed there was a better way to approach education outside of the classroom. Our proven and research-backed approach is based on the below core principles. Please <a href="#contact">contact us</a> if you have any questions.</p>
        </div>
        <AboutCards data={about}/>
    </div>
  );
};

export default About;