import React, { useState } from 'react';
import './ContactForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // emailJS template params
    const templateParams = {
      contact_name: name,
      contact_email: email,
      contact_message: message,
    };

    // send email via emailJS
    emailjs
      .send(
        'gmail_service',
        'contact_template',
        templateParams,
        'EYkqzrqev5UWvvBhu' 
      )
      .then((response) => {
        console.log('Email sent successfully:', response);
        setIsSuccess(true);
        // reset form fields
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setIsSuccess(false);
      });
  };

  return (
    <div className="contact-form border rounded">
      <h3 className="mb-2">
        <FontAwesomeIcon className="about-icon" icon={faEnvelope} size="xs" /> Contact Us
      </h3>
      <p>
        Fill out the form below to send us a message or ask us a question, and we will get back to you as soon as possible. Thank you!
      </p>

      {isSuccess !== null && (
        <div className={`alert ${isSuccess ? 'alert-success' : 'alert-danger'}`}>
          {isSuccess ? 'Your message has been sent successfully!' : 'There was an error sending your message. Please try again.'}
        </div>
      )}

      <form onSubmit={handleSubmit} method="POST" name="main-form">
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message <span style={{ color: 'red' }}>*</span>
          </label>
          <textarea
            className="form-control"
            id="message"
            name="message"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary mt-2">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;