import React from 'react';
import './Hero.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-header">
        <h1>Tailored <span>Learning</span> <FontAwesomeIcon icon={faBookOpen} className="hero-header-icon" size="sm"/></h1>
        <h1><FontAwesomeIcon icon={faUserGraduate} className="hero-header-icon" size="sm"/> <span>Certified</span> Educators</h1>
        <p>Private tutoring and academic support by professional educators. <a href="#about">Learn more</a>.</p>
        <a href="#contact" class="btn btn-primary btn-lg mt-4">Contact Us</a>
      </div>
      <div className="hero-image">
        <img src="/images/hero-header.jpg" alt="Tutoring"></img>
      </div>
    </div>
  );
};

export default Hero;