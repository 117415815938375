import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PrivacyPolicy.css';

const PrivacyPolicy = ({ visible, onClose }) => {
    return (
        <>
            {visible && (
                <div className="privacy-modal">
                    <div className="privacy-modal-content">
                        <button className="close-modal" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <h4 className="privacy-policy-header">Privacy Policy</h4>
                        <div className="privacy-policy-text">
                            <p>
                                <strong>Effective Date:</strong> 12/03/2024
                            </p>
                            <br />
                            <p>
                                <strong>Whaley Educational Services</strong> ("we," "our," or "us") values your privacy and is dedicated to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website, <a href="https://tutor4you.org" target="_blank" rel="noopener noreferrer">https://tutor4you.org</a> (the “Site”). By accessing or using our Site, you agree to the terms of this Privacy Policy.
                            </p>
                            <h4>1. Information We Collect</h4>
                            <p>
                                <strong>Personal Information:</strong>
                            </p>
                            <ul>
                                <li>Your name, email address, and other contact details you provide through forms or inquiries.</li>
                            </ul>
                            <p>
                                <strong>Non-Personal Information:</strong>
                            </p>
                            <ul>
                                <li>Device information such as IP address, browser type, operating system, and referring URLs.</li>
                                <li>Usage data including pages viewed, time spent on the Site, and click behavior.</li>
                            </ul>
                            <h4>2. How We Use Your Information</h4>
                            <ul>
                                <li>Provide, improve, and personalize our educational services.</li>
                                <li>Respond to your inquiries and fulfill requests.</li>
                                <li>Manage appointments, billing, and communications related to our services.</li>
                                <li>Analyze website performance and user experience.</li>
                            </ul>
                            <h4>3. Cookies and Tracking Technologies</h4>
                            <p>
                                We use cookies and similar technologies to:
                            </p>
                            <ul>
                                <li>Understand how visitors interact with our Site.</li>
                                <li>Enhance functionality and improve user experience.</li>
                            </ul>
                            <p>
                                You can modify your browser settings to refuse cookies, but some features of our Site may not function properly.
                            </p>
                            <h4>4. Sharing Your Information</h4>
                            <p>
                                We do not sell or rent your personal information. However, we may share it with:
                            </p>
                            <ul>
                                <li><strong>Service providers:</strong> For website hosting, payment processing, and analytics.</li>
                                <li><strong>Legal or regulatory authorities:</strong> To comply with applicable laws or respond to lawful requests.</li>
                            </ul>
                            <h4>5. Your Rights and Choices</h4>
                            <ul>
                                <li>Access, correct, or delete your personal information.</li>
                                <li>Limit or disable cookies through your browser settings.</li>
                            </ul>
                            <h4>6. Data Security</h4>
                            <p>
                                We use industry-standard security measures to protect your information. However, no online transmission is completely secure, and we cannot guarantee absolute security.
                            </p>
                            <h4>7. Third-Party Links</h4>
                            <p>
                                Our Site may contain links to external websites. We are not responsible for the privacy practices of these sites, and we encourage you to review their policies.
                            </p>
                            <h4>8. Children’s Privacy</h4>
                            <p>
                                We do not knowingly collect personal information from children under 13. If we learn we have inadvertently collected such information, we will take steps to delete it promptly.
                            </p>
                            <h4>9. Changes to This Policy</h4>
                            <p>
                                We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date. Please review it periodically to stay informed.
                            </p>
                            <h4>10. Contact Us</h4>
                            <p>
                                If you have any questions or concerns about this Privacy Policy, please contact us:
                            </p>
                            <br />
                            <p>
                                <strong>Whaley Educational Services, LLC</strong><br />
                                Website: <a href="https://tutor4you.org" target="_blank" rel="noopener noreferrer">https://tutor4you.org</a><br />
                                Email: <a href="mailto:mcwhaley2022@gmail.com" target="_blank" rel="noopener noreferrer">mcwhaley2022@gmail.com</a><br />
                                Phone: <a href="tel:+15123945068">(512) 394-5068</a>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PrivacyPolicy;